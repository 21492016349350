/* eslint-disable no-console */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react-hooks/exhaustive-deps */ import { toJS } from 'mobx';
import { observer } from 'mobx-react-lite';
import {
  MutableRefObject,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import { useLocation } from 'react-router';
import ReactToPrint from 'react-to-print';
import BaseDirectories from '../../../base directories/BaseDirectories';
import SignatureWithH4 from '../../../components/SignatureWithH4';
import Button from '../../../components/ui/Button';
import Spinner from '../../../components/ui/Spinner';
import { StoreContext } from '../../../mobx_stores/RootStore';

// import { ComponentToPrint } from './ComponentToPrint';

interface LocationState {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [key: string]: any;
}

const OfferLetter = () => {
  const [allLoans, setAllLoans] = useState<any>([]);
  const [loanDetails, setLoanDetails] = useState<any>({});
  const [loanState, setLoanState] = useState<any>({});
  const [userGuarantor, setUserGuarantor] = useState<any>({});
  const location: LocationState = useLocation();
  // eslint-disable-next-line @typescript-eslint/no-var-requires
  const componentRef: MutableRefObject<any> = useRef();

  const { loansStore, guarantorStore } = useContext(StoreContext);
  const { loading, message, loanUserdetails } = loansStore;
  const { guarantors } = guarantorStore;

  const [width, setWidth] = useState(window.innerWidth);

  useEffect(() => {
    function handleResize() {
      setWidth(window.innerWidth);
    }
    window.addEventListener('resize', handleResize);

    return () => window.removeEventListener('resize', handleResize);
  }, [width]);

  useEffect(() => {
    setLoanDetails(
      allLoans.filter((a: any) => a._id === location?.state?.loan?._id)[0],
    );
    setLoanState(location?.state?.loanState);
  }, [location, loansStore, allLoans]);

  useEffect(() => {
    const unsub = () => {
      guarantorStore.getGuarantors();
      guarantorStore.setGuarantors;
      setUserGuarantor(guarantors[0]);
    };
    return () => unsub();
  }, [location]);

  useEffect(() => {
    loansStore.getLoanById(location?.state?.loan?._id);
    loansStore.getAllLoans();
  }, [loansStore, location]);

  useEffect(() => {
    setAllLoans(toJS(loansStore.loans));
  }, [loansStore.loans]);

  const handleAcceptLoan = () => {
    if (loanDetails._id.length > 0) {
      loansStore.acceptLoan(loanDetails._id);
    }
  };

  const monthList = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ];

  const getCurrentDate = () => {
    const newDate = new Date();
    const date = newDate.getDate();
    const month = newDate.getMonth();
    const year = newDate.getFullYear();

    return `${date} ${monthList[month]}, ${year}`;
  };
  // console.log(location?.state);

  useEffect(() => {
    if (message.msg === 'You have successfully accepted loan.') {
      // window.location.reload();
      loansStore.getAllLoans();
      loansStore.getLoanById(loanDetails._id);
    }
  }, [message.type]);

  return (
    <div className="">
      {loading && <Spinner />}
      <div className="loans-container bg-white offer-letter pb-4">
        <div className="bg-white p-md-5 p-sm-4 p-3" ref={componentRef}>
          <div className="mb-3">
            <div className="d-flex align-items-center justify-content-between mb-4">
              <h6>{getCurrentDate()}</h6>
              <img
                style={{ width: '120px' }}
                src={`${BaseDirectories.LOGOS_DIR}/logo1.png`}
                alt=""
              />
            </div>
            <h6 className="mb-1">THE MANAGING DIRECTOR</h6>
            <p className="mb-1">
              {loanDetails?.loanDetails?.organizationDetails?.businessName},
            </p>
            <p className="mb-1">
              {loanDetails?.loanDetails?.organizationDetails?.businessAddress},
            </p>
            <p className="mb-1">
              {loanDetails?.loanDetails?.organizationDetails?.city},
            </p>
            <p className="mb-1">
              {loanDetails?.loanDetails?.organizationDetails?.state}.
            </p>
          </div>
          <h6 className="my-4">
            Attention: {`${loanDetails?.firstName} ${loanDetails?.lastName}`}
          </h6>
          <p>Dear Sir/Ma,</p>
          <div>
            <h5
              className="text-center"
              style={{ textTransform: 'uppercase', justifyContent: 'center' }}>
              PARTNERSHIP AGREEMENT
            </h5>
            <p
              className="mb-3 text-center"
              style={{ textTransform: 'uppercase', justifyContent: 'center' }}>
              (MUSHARAKAH VENTURE STRUCTURE)
            </p>
            <p>
              <b>THIS PARTNERSHIP AGREEMENT</b> (this “Agreement”) is made on{' '}
              {getCurrentDate()}
            </p>
            <p>
              <b>BETWEEN</b>
            </p>
            <p>
              <b>TRADE LENDA COM LIMITED RC 1793019</b>, a company incorporated
              under the laws of the Federal Republic of Nigeria and having its
              registered head office at 9B Ike Asogwa Close, Maryland, Ikeja,
              Lagos State (hereinafter referred to as <b>“TRADE LENDA”</b> which
              expression shall, where the context so admits, include its
              successors in title and assigns), of the first part.
            </p>
            <p>
              <b>AND</b>
            </p>
            <p>
              <b>{`${loanDetails?.firstName} ${loanDetails?.lastName}`}</b> of{' '}
              <b>
                {loanDetails?.loanDetails?.organizationDetails?.businessName}
              </b>{' '}
              (hereinafter referred to as <b>“PARTNER”</b>) which expression
              shall where the context so admits include its successors-in-title,
              principal officers and authorized assigns of the second part.
            </p>
          </div>

          <div>
            <h5 className="my-3">
              <b>WHEREAS:</b>
            </h5>
            <div
              className="d-flex justify-content-between term-group align-items-center mb-3"
              style={{ lineHeight: 2 }}>
              <ol type="A">
                <li>
                  Trade Lenda is a company which offers credit to customers to
                  finance contracts involving the supply or delivery of visible
                  items or products with specified contract agreements.
                </li>
                <li>
                  The partner is a business owner looking to engage in a
                  business venture with the contribution of Trade Lenda in
                  either monetary or valued assets form in which case the
                  allocated shares will be proportional to the assets.
                </li>
                <li>
                  Trade Lenda desires to contribute financially to the business
                  of the partner which entails{' '}
                  <b>
                    {loanDetails?.loanDetails?.organizationDetails?.industry}
                  </b>{' '}
                  with the goal to share the profits after deduction of
                  operating costs, expenses and taxes.{' '}
                  <b>(“Musharakah venture”) </b>in accordance with the law and
                  the terms and conditions of this Agreement.{' '}
                </li>
                <li>
                  The partner is willing to accept the credit facility offered
                  by Trade Lenda in accordance with the terms and conditions
                  agreed herein.
                </li>
                <li>
                  The parties have prior knowledge of this agreement and have
                  carefully ensured that the terms expressed reflect the
                  intention of the parties and have consequently agreed to abide
                  by its content.{' '}
                </li>
              </ol>
            </div>
            <h5 className="my-3">
              <b>NOW IT IS HEREBY MUTUALLY AGREED</b> as follows:
            </h5>
            <div
              className="d-flex justify-content-between term-group align-items-center mb-3"
              style={{ lineHeight: 2 }}>
              <ol className="nestedOl">
                <li>
                  <p className="underline-text">OBLIGATION OF PARTIES</p>
                  <ol>
                    <li>
                      Parties agree to contribute the capital toward the
                      advancement of the business venture in the ratio
                      respectively agreed by parties and outlined in schedule 1
                      of this agreement from the date of the party last signing
                      this agreement (Effective Date) or a date agreed by
                      parties, whichever comes first.
                    </li>
                    <li>
                      Parties agree that the contributions invested by both
                      parties will be strictly utilized for the musharakah
                      venture.
                    </li>
                    <li>
                      The percentage which will be agreed on for both parties
                      will be strictly on the profit realized from the venture
                      and not on the capital investment of parties.{' '}
                    </li>
                    <li>
                      Parties agree that profit will only be allocated between
                      partners after operating costs, expenses and taxes
                      incurred in the advancement of the venture have been
                      deducted.
                    </li>
                    <li>
                      Where the venture experiences a loss, parties shall bear
                      such loss in accordance with the capital investment ratio
                      evidenced in this agreement.
                    </li>
                    <li>
                      Trade Lenda agrees to participate in the venture by
                      strictly being an investing partner while{' '}
                      {
                        loanDetails?.loanDetails?.organizationDetails
                          ?.businessName
                      }{' '}
                      shall in addition to being an investor, manage and run the
                      musharakah venture.
                    </li>
                    <li>
                      Parties agree that Trade Lenda’s profit will be paid into
                      the provided Trade Lenda account in line with the agreed
                      ratio.{' '}
                    </li>
                    <li>
                      Parties hereby represent that they are duly formed and
                      validly existing entities with the capacity to enter into
                      this agreement.
                    </li>
                  </ol>
                </li>

                <li>
                  <p className="underline-text">TERM AND TERMINATION</p>
                  <ol>
                    <li>
                      Parties agree that this musharakah venture agreement may
                      be deemed terminated with a thirty (30) days’ notice in
                      the following circumstances:
                      <ol>
                        <li>By mutual consent of the parties</li>
                        <li>
                          By a Party, if the other Party has breached the terms
                          of this Agreement so that the purpose of this
                          Agreement is rendered ineffective, and the breaching
                          Party, where such breach is redeemable, fails to
                          remedy the breach within seven (7) business days after
                          its receipt of a written notice clearly stating facts
                          which constitute a breach of this Agreement from the
                          other Party.
                        </li>
                        <li>
                          By a Party, if the other Party has transferred its
                          rights and obligations under this Agreement to any
                          third Party without the prior written consent of the
                          other Party.
                        </li>
                        <li>
                          By a Party where the other Party goes into
                          liquidation, becomes bankrupt, makes a voluntary
                          arrangement with its creditors, or has a receiver or
                          administrator appointed.
                        </li>
                        <li>
                          If the purpose for which the venture was formed has
                          been achieved.
                        </li>
                      </ol>
                    </li>
                    <li>
                      The termination of this Agreement for any reason shall not
                      affect the accrued rights, remedies, or liabilities of the
                      parties or the continuance in force of any provision of
                      this Agreement which is expressly or by implication
                      intended to continue in force after termination.
                    </li>
                    <li>
                      Other than as stipulated in this Agreement, neither Party
                      shall have any further obligation to the other under this
                      Agreement on or after its expiry or termination.
                    </li>
                  </ol>
                </li>

                <li>
                  <p className="underline-text">MISCELLANEOUS</p>
                  <ol>
                    <li>
                      No waiver or amendment to this Agreement shall be binding
                      upon the parties unless it is in writing and duly executed
                      by both Parties.
                    </li>
                    <li>
                      No term shall survive at the expiry or termination of this
                      Agreement unless expressly provided.
                    </li>
                    <li>
                      No delay or failure by either party to exercise any of its
                      powers, rights or remedies under this Agreement will
                      operate as a waiver of them, nor will any single or
                      partial exercise of any such powers, rights or remedies
                      preclude any other or future exercise of them.
                    </li>
                    <li>
                      Parties shall not assign its rights, obligations, or
                      liabilities under this Agreement to any third Party
                      without each other’s prior written approval first sought
                      and obtained, and such approval shall not be unreasonably
                      withheld.
                    </li>
                  </ol>
                </li>

                <li>
                  <p className="underline-text">NOTICES</p>
                  <ol>
                    <li>
                      Any notice or demand or information required to be given
                      under this agreement shall be in writing and should be
                      delivered personally or sent via electronic email to such
                      known addresses of the parties at the time of execution of
                      this contract. The parties must inform each other of any
                      change in address.
                    </li>
                    <li>
                      A Notice given in accordance with this agreement will be
                      effective upon receipt by the Party to which it is given
                      and if by personal delivery or, if emailed, upon automated
                      confirmation of receipt of the notice.
                    </li>
                  </ol>
                </li>

                <li>
                  <p className="underline-text">
                    GOVERNING LAW AND DISPUTE RESOLUTION
                  </p>
                  <ol>
                    <li>
                      The construction, performance, execution and enforcement
                      of this Agreement and any dispute of whatsoever nature
                      arising in connection with this Agreement or performance
                      under it, including any remedy thereof, shall be governed
                      exclusively by the laws of the Federal Republic of
                      Nigeria.
                    </li>
                    <li>
                      In the event of any disputes, controversy or claim arising
                      out of, in relation to and/or in connection with this
                      Agreement or the duties or obligations to be observed and
                      performed in this Agreement, or the breach thereof, the
                      Parties hereto shall endeavor to resolve same between them
                      amicably.
                    </li>
                    <li>
                      If the Parties fail to resolve any dispute within thirty
                      (30) days, then such dispute shall be determined by
                      arbitration administered by the Lagos State Multi Door
                      Courthouse.
                    </li>
                  </ol>
                </li>
              </ol>
            </div>
          </div>
          <div className="mb-1 text-justify" style={{ textAlign: 'justify' }}>
            <p className="mb-0">
              <b>IN WITNESS WHEREOF</b> the parties hereto have set their hands
              and seals the day and year first above written.
            </p>
          </div>
          <div className="mb-5 text-justify" style={{ textAlign: 'justify' }}>
            <p className="mb-5">
              Signed by the authorized representatives of{' '}
              <b>TRADE LENDA COM LIMITED</b>
            </p>
            <div className="d-flex justify-content-between text-center signature">
              <div
                className="dir"
                style={{
                  backgroundSize: 'cover',
                  backgroundPosition: 'center -100px',
                  height: '70px',
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  justifyContent: 'flex-end',
                  lineHeight: '0px',
                }}>
                <SignatureWithH4
                  image={`${BaseDirectories.IMAGES_DIR}/Adeshina Signature-01.png`}
                  name={`Adeshina Adewunmi`.toUpperCase()}
                />
              </div>
            </div>
          </div>

          <div className="mb-5 text-justify" style={{ textAlign: 'justify' }}>
            <p>
              Signed by the authorized representatives of{' '}
              <b>
                {loanDetails?.loanDetails?.organizationDetails?.businessName.toUpperCase()}
              </b>
            </p>
          </div>

          <div className="d-flex justify-content-between text-center signature">
            <div
              className="dir"
              style={{
                backgroundSize: 'cover',
                backgroundPosition: 'center -100px',
                height: '70px',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'flex-end',
                lineHeight: '0px',
              }}>
              <p
                className=""
                style={{
                  lineHeight: '0px',
                }}>
                <span className="pt-4 pb-0">{`${loanDetails?.firstName} ${loanDetails?.lastName}`}</span>

                <h4>______________________</h4>
              </p>
              <h5>DIRECTOR</h5>
            </div>
            <div
              className="sec"
              style={{
                backgroundSize: 'cover',
                backgroundPosition: 'center -104px',
                height: '70px',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'flex-end',
              }}>
              <p
                className=""
                style={{
                  lineHeight: '0px',
                }}>
                <span className="pt-4 pb-0">{`${
                  userGuarantor?.firstName
                    ? userGuarantor?.firstName
                    : loanDetails?.firstName
                } ${
                  userGuarantor?.lastName
                    ? userGuarantor?.lastName
                    : loanDetails?.lastName
                }`}</span>
                <h4>______________________</h4>
                <h5>DIRECTOR/GUARANTOR</h5>
              </p>
            </div>
          </div>
          <div
            className="mt-5 mb-1 text-justify"
            style={{ textAlign: 'justify' }}>
            <table>
              <tr>
                <th>
                  <mark>PARTIES</mark>
                </th>
                <th>
                  <mark>CAPITAL INVESTMENT</mark>
                </th>
                <th>
                  <mark>PROFIT SHARING RATIO (%)</mark>
                </th>
              </tr>
              <tr>
                <td>TRADE LENDA COM LIMITED</td>
                <td>
                  ₦{' '}
                  {location?.state?.loanState?.totalrepaymentAmount?.toLocaleString() ||
                    0}
                </td>
                <td>{location?.state?.loanState?.interest || 0} %</td>
              </tr>
              <tr>
                <td style={{ textTransform: 'uppercase' }}>
                  {loanDetails?.loanDetails?.organizationDetails?.businessName}
                </td>
                <td>₦ </td>
                <td>
                  {100 - Number(location?.state?.loanState?.interest) || 100} %
                </td>
              </tr>
            </table>
          </div>
        </div>
        <div className="row justify-content-center text-center mt-4">
          <div className="col-lg-8 col-md-8">
            <div className="profile-btn d-flex gap-3 align-items-center justify-content-center">
              {loanDetails?.approvalStatus === 'approved' &&
                // !toJS(loansStore.loanId).acceptLoanOffer &&
                // authorized &&
                !toJS(loansStore.loanId).acceptLoanOffer && (
                  <Button
                    disabled={toJS(loansStore.loanId).acceptLoanOffer}
                    classes="primary-btn btn-lg px-4"
                    content={
                      loanUserdetails?.organizationDetails?.shariaCom
                        ? 'Accept Facility'
                        : 'Accept Loan'
                    }
                    onClick={handleAcceptLoan}
                  />
                )}
              <ReactToPrint
                trigger={() => (
                  <Button
                    classes="secondary-btn btn-lg px-4 cancel"
                    content="Print"
                  />
                )}
                content={() => componentRef.current}
              />
              {(loanState?.acceptLoanOffer ||
                loanState?.acceptLoanOfferDate?.length > 0 ||
                toJS(loansStore.loanId).acceptLoanOffer) && (
                <Button
                  classes="btn-success btn-lg px-4"
                  disabled={true}
                  content="Accepted"
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default observer(OfferLetter);
