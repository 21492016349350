import React, { useEffect, useState } from 'react';
import Input from '../ui/Input';
import Button from '../ui/Button';
import { observer } from 'mobx-react-lite';
import { StoreContext } from '../../mobx_stores/RootStore';
import { toast } from 'react-toastify';
import { toJS } from 'mobx';

const BvnValidationForm = ({ next, setX, previous }) => {
  const { profileStore } = React.useContext(StoreContext);
  const { submitting, message } = profileStore;

  const [bvnValue, setBvnValue] = useState('');
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [response, setResponse] = useState([]);
  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setBvnValue(e.target.value);
  };

  function validateBVNLength(bvn: string) {
    return bvn.length === 11;
  }
  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (!validateBVNLength(bvnValue)) {
      toast.error('BVN must be 11 digits');
      return;
    } else {
      profileStore.validateBVN(bvnValue);
      setResponse(profileStore.bvn);
      // toast.success('BVN validation successful');
    }
    // next();
  };

  useEffect(() => {
    console.debug(toJS(profileStore).bvn);
    if (
      message.type === 'success' &&
      message.msg === 'BVN validated successfully'
    ) {
      setTimeout(() => {
        next();
      }, 1000);
    }
  }, [message.type, message.msg]);

  return (
    <form onSubmit={handleSubmit} className="row justify-content-center">
      <div className="col-md-6 justify-content-center">
        <label htmlFor="bvn" className="mb-1 mt-3">
          BVN (Bank Verification Number)
          <span style={{ color: 'red' }}> *</span>
        </label>
        <Input
          name="bvn"
          classes="form-control"
          type="text"
          id="bvn"
          value={bvnValue}
          onChange={handleInputChange}
          required
          style={{
            border: 'none',
            borderBottom: '2px solid #ccc',
            borderRadius: '0px',
            outline: 'none',
            backgroundColor: 'transparent',
          }}
        />
      </div>
      <div className="row justify-content-center">
        <div className="col-lg-6 col-md-8">
          <div className="profile-btn-bvn">
            <Button
              classes="white-btn border-2 w-full"
              content="Previous"
              onClick={() => {
                previous();
                setX(-1000);
              }}
            />
            <Button
              classes="primary-btn w-full"
              content={submitting ? 'Submitting...' : 'Validate'}
              type="submit"
            />
          </div>
        </div>
      </div>
    </form>
  );
};

export default observer(BvnValidationForm);
