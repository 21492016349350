/* eslint-disable @typescript-eslint/no-unused-vars */
/** eslint-disable no-console */

import { mdiClose } from '@mdi/js';
import Icon from '@mdi/react';
import { runInAction, toJS } from 'mobx';
import { observer } from 'mobx-react-lite';
import { ChangeEvent, FormEvent, useContext, useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import BaseDirectories from '../../../base directories/BaseDirectories';
import {
  TransferType,
  VfdAccountEnquiryDto,
} from '../../../common/interfaces/n_wallet_interfaces';
import Button from '../../../components/ui/Button';
import Input from '../../../components/ui/Input';
import { StoreContext } from '../../../mobx_stores/RootStore';
import { PayoutAction } from '../../../mobx_stores/WalletStore';
import InputPinModal from './InputPinModal';

const TransferToBankModal = ({
  setOpenBankTransferModal: setOpenBankTransferModal,
}) => {
  const [showBankSelection, setShowBankSelection] = useState(true);
  const [showAmountInput, setShowAmountInput] = useState(false);
  const [transferDetails, setTransferDetails] = useState({
    fromWalletIdAccountNumber: '',
    receiverAccountFirstName: '',
    receiverAccountLastName: '',
    receiverAccountNumber: '',
    receiverBankName: '',
    amount: '',
    narration: '',
    saveBeneficiary: false,
    transactionPin: '',
    passKey: '',
    banker: '',
    senderBankProvider: '',
    bankCode: '',
  });

  const [showPinModal, setShowPinModal] = useState(false);
  const [mainTransferStatus, setTransferStatus] = useState('');
  const [showTransferStatus, setShowTransferStatus] = useState(false);

  const { walletStore, nWalletStore } = useContext(StoreContext);
  const { message, setMessage, multipleWallet, vfdAccountDetails } =
    walletStore;

  const { vfdBanks, receiver, gettingBanks, gettingReceiverAccountDetails } =
    nWalletStore;

  useEffect(() => {
    if (
      nWalletStore.transferStatus == '16' ||
      nWalletStore.transferStatus == '00'
    ) {
      setShowPinModal(false);
      setOpenBankTransferModal(false);
      nWalletStore.transferStatus = '';
    }
  }, [nWalletStore.transferStatus]);

  const handleNipAccountVerification = () => {
    if (
      !transferDetails.receiverAccountNumber ||
      !transferDetails.receiverBankName
    ) {
      toast.error(
        'Please make sure bank and account number are filled to verify account name',
      );
      return;
    }

    if (transferDetails.receiverBankName) {
      const beneficiaryDetails: VfdAccountEnquiryDto = {
        accountnumber: transferDetails.receiverAccountNumber,
        /// change to bank code
        bankcode: transferDetails.bankCode,
        transfer_type: TransferType.Inter,
      };

      vfdBanks.forEach((vfdBank) => {
        if (vfdBank.name === transferDetails.receiverBankName) {
          console.log('bankCode: ', vfdBank.code);
          beneficiaryDetails.bankcode = vfdBank.code;
        }
      });

      nWalletStore.getBeneficiaryDetails(beneficiaryDetails);
    }
  };

  const handleInputChange = (e: ChangeEvent) => {
    const element = e.target as HTMLInputElement;
    const val =
      element.name === 'amount'
        ? element.value.length === 0
          ? element.value
          : parseFloat(element.value)
        : element.value;

    setTransferDetails((prevDetails: any) => {
      vfdBanks.forEach((vfdBank) => {
        if (vfdBank.name === transferDetails.receiverBankName) {
          console.log('bankCode: ', vfdBank.code);
          transferDetails.bankCode = vfdBank.code;
          return { ...prevDetails, bankCode: vfdBank.code };
        }
      });

      if (vfdAccountDetails?.accountNo) {
      }

      return { ...prevDetails, [element.name]: val };
    });
  };

  const validate = (evt: any) => {
    const theEvent = evt || window.event;

    let key = theEvent.keyCode || theEvent.which;
    key = String.fromCharCode(key);

    const regex = /^[0-9]{0,9}$/;
    // const regex = /[0-9]/;
    if (
      !regex.test(transferDetails.receiverAccountNumber) ||
      !regex.test(key)
    ) {
      theEvent.returnValue = false;
      if (theEvent.preventDefault) theEvent.preventDefault();
    }
  };

  const validateAmount = (evt: any) => {
    const theEvent = evt || window.event;
    let key = theEvent.keyCode || theEvent.which;
    key = String.fromCharCode(key);

    // Allow numbers, decimal point, and backspace
    const regex = /[0-9\.]|Backspace/;
    // const regex = /^(?!0\d)\d*(\.\d*)?$|^$/;
    if (!regex.test(key)) {
      theEvent.returnValue = false;
      if (theEvent.preventDefault) theEvent.preventDefault();
    }
  };

  const disableIt = (receiver?.data?.name || '').length < 1;

  const handleShowPinModal = () => {
    const {
      receiverAccountFirstName,
      receiverAccountLastName,
      receiverAccountNumber,
      receiverBankName,
      amount,
      narration,
    } = transferDetails;
    if (
      // receiverAccountFirstName === '' ||
      // receiverAccountLastName === '' ||
      // receiverAccountNumber === '' ||
      // receiverBankName === '' ||
      amount === ''
      // ||
      // narration === ''
    ) {
      toast.error('Amount is Required');
      return;
    }
    setShowPinModal(true);
  };

  const handleTransferToBank = async (e: FormEvent) => {
    e.preventDefault();

    if (transferDetails.amount >= walletStore.vfdAccountBallance) {
      toast.error('Insufficient Funds');
      return;
    }

    if (transferDetails.transactionPin.length < 4) {
      toast.error('Please check the pin and try again.');
      return;
    }

    /// TODO: Add Pin Validator
    // walletStore.transferToBank(transferDetails);
    const limit =
      toJS(multipleWallet).find((wallet) => wallet.banker === 'VFD')
        ?.transactionLimit ?? 50000;

    const transferDto = {
      amount: transferDetails.amount,
      transferType: TransferType.Inter,
      remark: transferDetails.narration,
      senderAccountNumber: vfdAccountDetails?.accountNo,
      recipientBankCode: transferDetails.bankCode,
      limit: limit,
      metaData: {
        userId: walletStore.multipleWallet[0].userId,
        transactionPin: transferDetails.transactionPin,
      },
    };
    await nWalletStore.getSenderDetails(transferDto.senderAccountNumber);

    nWalletStore.transfer(transferDto);
  };

  useEffect(() => {
    if (message.msg) {
      setTransferStatus(message.msg);
      setShowTransferStatus(true);
    } else {
      // setTransferStatus(message.msg);
      setShowTransferStatus(false);
    }
  }, [message.msg]);

  return (
    <div className="notification-modal">
      <div className="bg-white notification-box">
        <span
          className="close"
          onClick={() => {
            setOpenBankTransferModal(false);
            runInAction(() => {
              setMessage('', '');
            });
          }}>
          <Icon
            path={mdiClose}
            size={1}
            className="goto-icon me-2"
            title="Close"
          />
        </span>
        <img
          src={`${BaseDirectories.IMAGES_DIR}/greenarrowforward.svg`}
          alt=""
          style={{ width: '20%' }}
          className="mb-4"
        />

        <h4 className="mb-2">Bank account</h4>
        <p className="mb-2">Transfer money to your external bank account.</p>
        {!showBankSelection &&
          transferDetails?.fromWalletIdAccountNumber[0] == '4' && (
            <p className="mb-4">Transfer charges of ₦25 may apply.</p>
          )}

        {!showAmountInput && !showBankSelection && (
          <div className="mb-3 amount">
            <h6>
              ₦
              {transferDetails.amount
                ? new Intl.NumberFormat('en-US', {
                    style: 'decimal',
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  }).format(Number(transferDetails.amount))
                : '0.00'}
            </h6>
          </div>
        )}

        {!showTransferStatus ? (
          <form className="add-guarantor-form" onSubmit={handleTransferToBank}>
            {showPinModal ? (
              <InputPinModal
                setDetails={setTransferDetails}
                setShowPinModal={setShowPinModal}
              />
            ) : !showAmountInput && !showBankSelection ? (
              <>
                <div className="row justify-content-center account-box">
                  <div className="col-11">
                    <label
                      htmlFor="receiverBankName"
                      className="mb-1 mt-2 text-start d-block">
                      Bank
                    </label>
                    <input
                      list="banks"
                      className="form-control"
                      onChange={handleInputChange}
                      name="receiverBankName"
                      placeholder={
                        gettingBanks ? 'Retrieving banks...' : 'Select Bank'
                      }
                      disabled={gettingBanks}
                    />
                    <datalist id="banks">
                      {toJS(vfdBanks)
                        .filter(({ id }) => id !== 45)
                        .map(({ name }, index: number) => (
                          <option key={index} value={name} />
                        ))}
                    </datalist>
                  </div>
                  {/* <div className="row"> */}
                  {transferDetails.receiverBankName &&
                    transferDetails.receiverBankName.length > 0 && (
                      <>
                        <div className="col-11">
                          <label
                            htmlFor="receiverAccountNumber"
                            className="mb-1 mt-2 text-start d-block">
                            Account Number
                          </label>
                          <Input
                            onKeyPress={validate}
                            maxLength={10}
                            // onPaste={validate}
                            autoComplete="off"
                            onChange={(e: ChangeEvent) => {
                              handleInputChange(e);
                              runInAction(() => {
                                walletStore.bankReceiverFullName = '';
                              });
                            }}
                            name="receiverAccountNumber"
                            classes="form-control"
                            type="number"
                            id="receiverAccountNumber"
                            value={
                              transferDetails.receiverAccountNumber &&
                              Number(transferDetails.receiverAccountNumber)
                            }
                          />
                        </div>
                        {transferDetails.receiverAccountNumber &&
                          transferDetails.receiverAccountNumber.length >=
                            10 && (
                            <>
                              <div className="col-11">
                                <label
                                  htmlFor="accountName"
                                  className="mb-1 mt-2 text-start d-block">
                                  Account Name
                                </label>
                                <Input
                                  onFocus={handleNipAccountVerification}
                                  name="accountName"
                                  classes="form-control"
                                  type="text"
                                  id="accountName"
                                  value={
                                    receiver?.data?.name ??
                                    (gettingReceiverAccountDetails
                                      ? 'Fetching Account Info..'
                                      : '')
                                  }
                                  readonly={true}
                                  onChange={() => null}
                                />
                              </div>
                              {(receiver?.data?.name || '') &&
                                (receiver?.data?.name || '').length > 0 && (
                                  <div className="col-11">
                                    <label
                                      htmlFor="narration"
                                      className="mb-1 mt-2 text-start d-block">
                                      Narration
                                    </label>
                                    <Input
                                      onChange={handleInputChange}
                                      name="narration"
                                      classes="form-control"
                                      type="text"
                                      id="narration"
                                      value={transferDetails.narration}
                                    />
                                  </div>
                                )}
                            </>
                          )}
                      </>
                    )}
                  <div className="col-11 text-start mt-3">
                    <Input
                      onChange={() =>
                        setTransferDetails((prevDetails) => {
                          return {
                            ...prevDetails,
                            saveBeneficiary: !transferDetails.saveBeneficiary,
                          };
                        })
                      }
                      name="saveBeneficiary"
                      classes="me-2"
                      type="checkbox"
                      id="saveAccount"
                      checked={transferDetails.saveBeneficiary}
                    />
                    <label htmlFor="saveAccount">Save Account</label>
                  </div>
                </div>
                <div className="row justify-content-center mt-2">
                  <div className="col-11">
                    <div className="group-btn justify-content-center mt-2">
                      <Button
                        classes="secondary-btn btn-md"
                        content="Back"
                        onClick={() => setShowAmountInput(true)}
                        type="button"
                      />
                      <Button
                        classes="primary-btn btn-md"
                        disabled={disableIt}
                        content="Proceed"
                        type="button"
                        onClick={handleShowPinModal}
                      />
                    </div>
                  </div>
                </div>
              </>
            ) : showAmountInput && !showBankSelection ? (
              //AMOUNT INPUT
              <>
                <div className="row justify-content-center amount-box">
                  {transferDetails.banker === '9 Payment Service Bank' ? (
                    <div className="col-10">
                      <label
                        htmlFor="amount"
                        className="mb-1 text-start d-block">
                        OTP
                      </label>
                      <Input
                        onChange={handleInputChange}
                        name="passKey"
                        classes="form-control mb-1"
                        type="number"
                        id="passKey"
                        minLength={2}
                        value={transferDetails.passKey}
                      />
                    </div>
                  ) : null}
                </div>
                <div className="row justify-content-center amount-box">
                  <div className="col-10">
                    <label htmlFor="amount" className="mb-1 text-start d-block">
                      Amount
                      <span
                        className="text-danger small"
                        style={{
                          float: 'right',
                        }}>
                        Minimum ₦50
                      </span>
                    </label>
                    <Input
                      onKeyPress={validateAmount}
                      onChange={handleInputChange}
                      name="amount"
                      classes="form-control mb-1"
                      type="number"
                      id="amount"
                      value={transferDetails.amount}
                    />
                    <span className="small">
                      <h4
                        className="small mb-3"
                        style={{
                          float: 'right',
                        }}>
                        Balance: ₦{' '}
                        {toJS(multipleWallet) &&
                          toJS(multipleWallet)?.length > 0 &&
                          toJS(multipleWallet).map(
                            (walletData: any) =>
                              walletData.walletIdAccountNumber ==
                                transferDetails?.fromWalletIdAccountNumber &&
                              (walletData?.availableBalance != 'NaN' ||
                              walletData?.availableBalance != null ||
                              walletData?.availableBalance != undefined
                                ? walletData?.availableBalance
                                  ? new Intl.NumberFormat('en-US', {
                                      style: 'decimal',
                                      minimumFractionDigits: 2,
                                      maximumFractionDigits: 2,
                                    }).format(walletData?.availableBalance)
                                  : '0.00'
                                : '0.00'),
                          )}
                      </h4>
                    </span>
                  </div>
                  <div className="row justify-content-center mt-2">
                    <div className="col-11">
                      <div className="group-btn justify-content-center">
                        <Button
                          classes="secondary-btn btn-md"
                          content="Back"
                          onClick={() => {
                            setShowAmountInput(false);
                            setShowBankSelection(true);
                          }}
                          type="button"
                        />
                        <Button
                          classes="primary-btn btn-md"
                          content="Next"
                          type="button"
                          onClick={() => {
                            if (Number(transferDetails.amount) < 50) {
                              toast.error('Amount must be greater than ₦50');
                              return;
                            }

                            transferDetails.amount && setShowAmountInput(false);
                          }}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </>
            ) : (
              // BANK SELECTION
              <div className="row justify-content-center banks-box">
                <p className="mb-4 color-heading">Select a wallet</p>
                <div className="col-12">
                  {toJS(multipleWallet) && toJS(multipleWallet)?.length > 0
                    ? toJS(multipleWallet)?.map(
                        (walletData: any, index: number) => (
                          <div className="w-100 gap-2 mt-2" key={index}>
                            <h6 className="wallet-deets d-flex align-items-start my-3 color-heading">
                              Wallet {index + 1}
                            </h6>
                            <div
                              className="d-flex flex-wrap gap-20 align-items-center justify-content-between mb-3 wallet-deets bank-selection"
                              onClick={() => {
                                if (walletData.banker !== 'VFD') {
                                  walletStore.initializePayout(
                                    PayoutAction.PrimaryPayOut,
                                  );
                                }

                                if (walletData?.availableBalance < 100) {
                                  toast.error(
                                    'Amount must be greater than ₦100',
                                  );
                                } else {
                                  // get banks list for specific wallet by provider
                                  if (walletData?.banker == 'Providus') {
                                    walletStore.getProvidusNipBanks();
                                  }
                                  if (
                                    walletData?.banker ===
                                    '9 Payment Service Bank'
                                  ) {
                                    walletStore.getSeerbitNipBanks();
                                  }
                                  setTransferDetails((prevDetails) => {
                                    return {
                                      ...prevDetails,
                                      fromWalletIdAccountNumber:
                                        walletData?.walletIdAccountNumber,
                                      banker: walletData?.banker,
                                      senderBankProvider: walletData?.banker,
                                    };
                                  });
                                  setShowBankSelection(false);
                                  setShowAmountInput(true);
                                }
                              }}>
                              <h6>{walletData?.banker}</h6>
                              <p>
                                ₦{' '}
                                {walletData?.availableBalance != 'NaN' ||
                                walletData?.availableBalance != null ||
                                walletData?.availableBalance != undefined
                                  ? walletData?.availableBalance
                                    ? new Intl.NumberFormat('en-US', {
                                        style: 'decimal',
                                        minimumFractionDigits: 2,
                                        maximumFractionDigits: 2,
                                      }).format(walletData?.availableBalance)
                                    : '0.00'
                                  : '0.00'}
                              </p>
                            </div>
                          </div>
                        ),
                      )
                    : null}
                </div>
              </div>
            )}
          </form>
        ) : (
          <div>
            <h6
              className={
                message.type === 'success'
                  ? ' alert alert-success mb-0'
                  : 'alert alert-danger mb-0'
              }
              role="alert">
              {mainTransferStatus}
            </h6>
          </div>
        )}
      </div>
    </div>
  );
};

export default observer(TransferToBankModal);
